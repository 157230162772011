import React, { useEffect, useState } from 'react';
import Loader from '../../Components/Loader.tsx';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick/lib/slider';

const settings = {
    dots: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 8,
    slidesToScroll: 8,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            }
        }
    ]
};


const categories = [
    "All Categories",
    "Business",
    "Tech",
    "Culture",
    "Lifestyle",
    "Finance",
    'Legal',
    'Healthcare',
    'Crypto',
    'Energy',
    'Education',
    'Sports',
    'Entertainment',
    'Automotive',
    'Science',
    'Mining'
];

const NewsroomPress = () => {
    interface PressRelease {
        id: string;
        title: string;
        industry: string;
        logo: string;
        published_date: string;
    }
    
    const [pressReleases, setPressReleases] = useState<PressRelease[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [selectedCategory, setSelectedCategory] = useState('All Categories');

    useEffect(() => {
        const apiURL = 'https://portal.thrivenewswire.com/api/v1/all-press-release';
        const fetchData = async () => {
            try {
                const response = await fetch(apiURL);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setPressReleases(result?.data?.pressReleases);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className='loader'><Loader /></div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const handleCardClick = (id) => {
        const newTab = window.open(`/press-release/${id}`, '_blank');
        if (newTab) {
            newTab.focus();
        }
    };

    const filteredPressReleases = selectedCategory === 'All Categories'
        ? pressReleases
        : pressReleases.filter(item => item?.industry === selectedCategory);

    return (
        <div className="newsroom-container">
            {/* Category Tabs */}
            <div className="categories">
                <Slider {...settings}>
                    {categories.map((category, index) => (
                        <span
                            key={index}
                            className={`category-tab ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => setSelectedCategory(category)}
                        >
                            {category}
                        </span>
                    ))}
                </Slider>
            </div>

            {/* Check if there are any press releases to show */}
            {filteredPressReleases?.length === 0 ? (
                <div className="no-press-releases">
                    No press releases available.  Try different category.
                </div>
            ) : (
                <div className="grid-container">
                    {filteredPressReleases.map((item) => (
                        <div className="cardPress" key={item.id} >
                            <img src={`https://portal.thrivenewswire.com/storage/${item?.logo}`} alt={item?.title} className="card-logo" />
                            <div className="card-body">
                                <h3 className="press-release-title">{item?.title}</h3>
                                <p><strong>Published Date:</strong> {item?.published_date}</p>
                                <p className='read-press' onClick={() => handleCardClick(item?.id)}>Read Press Release &gt;&gt;</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NewsroomPress;
